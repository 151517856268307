import React from 'react';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { wrapper1224 } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import ResetPasswordForm from '../../components/Auth/Form/ResetPasswordForm';


const WrapperDesktop = styled('div')`
  ${wrapper1224}
  .reset-password-wrap {
    padding: 10% 20px;
    text-align: left;
    margin: 20px auto;
    background: rgba(255,255,255,0.05);
    border-radius: 10px;
  }
  .reset-form-wrap {
    max-width: 400px;
    margin: 0 auto;
  }
`;




const PageTemplate = ({ pageContext }) => {
  const metaTitle = "รหัสผ่านใหม่ - " + process.env.OWNER;
  const metaDesc = "Have an account and want to update password? You're exactly where you need to be!";
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = "เปลี่ยนรหัสผ่าน";

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={pageContext.path}
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        <div className="reset-password-wrap">
          <div className="reset-form-wrap">
            <ResetPasswordForm/>
          </div>
        </div>
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;