import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, formStyles, sizing } from '../../../utils/style';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { newPasswordUrl } from '../Config.js';
import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext';
import withLocation from '../../shared/withLocation';
import { getAuthHeader } from '../Utils';

const Wrapper = styled('div')`
  ${formStyles}
  position: relative;
  .errorMsg, .successMsg {
    display: none;
    &.visible {
      display: block;
    }
  }
  
  .submit-loading {
    svg {
      margin-left: 10px;
      animation: spin 2000ms linear infinite;
    }
  }
  
  .notice {
    color: ${color.transparentWhite7};
    margin: 0 0 20px;
    font-size: 14px;
    font-weight: normal;
  }

  .loading {
    position: relative;
  }
  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .button {
    margin: 0 !important;
    svg {
      margin-left: 5px;
    }
    &:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .inputName {
    margin-bottom: 5px;
  }
  .forgot-password {
    font-size: ${sizing.small};
  }
`;

const MsgWrap = styled('div')`
  margin: 10px 0 30px;
  span {
    display: none;
    background-color: ${color.yellow};
    color: ${color.black};
    padding: 5px 10px;
    text-align: center;
    border-radius: 4px;
    &.visible {
      display: block;
    }
  }
`;

const BtnWrapper = styled('div')`
  margin: 15px 0;
  .button {
    width: 100%;
  }
`;

let setUsernameCallback = false;
class ResetPasswordForm extends Component {
  static contextType = StoreContext;
  search = this.props.search;
  showErrorMsg = msg => {
    const errorMsg = document.getElementById('resetPassErrorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  showSuccessMsg = () => {
    const successMsg = document.getElementById('resetPassSuccessMsg');
    console.log(successMsg);
    successMsg.classList.add('visible');
    const popupHeader = document.getElementById('reset-password-popup-title');
    if (popupHeader) {
      popupHeader.style.display = 'none';
    }
  };

  onSubmit = async (values, formApi) => {
    values.email = this.search.email ? this.search.email : '';
    values.phone = this.search.phone ? this.search.phone : '';
    values.code = this.search.code ? this.search.code : '';
    const body = JSON.stringify(values);
    //console.log('Page context:');
    //console.log(this.context);
    //console.log(getAuthHeader());
    const username =  this.context.username;

    let response = null;

    //for logged in user
    if(username) {
      response = await fetch(newPasswordUrl, {
        method: 'post',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthHeader()
        },
      });
    } else {
      //for not logged in user
      response = await fetch(newPasswordUrl, {
        method: 'post',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    if (response && response.ok) {
      let json = await response.json();
      if (json.success) {
        this.showSuccessMsg();
        formApi.setSubmitting(false);
      }
    } else {
      let json = await response.json();
      this.showErrorMsg('ขออภัย ยูสเซอร์เนมและ/หรือรหัสผ่านไม่ถูกต้อง');
      formApi.setSubmitting(false);
      console.log(
        'Login HTTP Error: ' + response.status + '. Error: ' + json.message
      );
    }
  };

  render() {
    const value = this.context;
    console.log('Search:');
    console.log(this.search);
    setUsernameCallback = value.setUsernameCallback;
    const initialValues = {
      password: '',
      passwordConfirmed: '',
    };
    return (
      <Wrapper>
        {value.username || Object.keys(this.search).length ?
          <div>
            <div className="successMsg" id="resetPassSuccessMsg">
              <IcomoonIcon icon="check" color="#ffcdcd" size={46}/>
              <div className="successMsgText">
                <p>อัปเดตรหัสผ่านของคุณเรียบร้อยแล้ว! โปรดใช้รหัสผ่านใหม่ในการเข้าสู่ระบบครั้งต่อไป</p>
              </div>
            </div>
            <p className="notice">โปรดพิมพ์รหัสผ่านใหม่ด้านล่างนี้: {this.search.email}</p>
            <Formik
              initialValues={initialValues}
              onSubmit={this.onSubmit}
              validate={values => {
                let errors = {};
                if (values.password !== values.passwordConfirmed) {
                  errors.passwordConfirmed = 'รหัสผ่านไม่ตรงกัน'
                }
                if (values.password.length < 6) {
                  errors.password = 'อย่างน้อย 6 ตัวอักษร'
                }
                return errors;
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().required('จำเป็นต้องใช้'),
                passwordConfirmed: Yup.string().required('จำเป็นต้องใช้'),
              })}
              render={({
                         errors,
                         status,
                         touched,
                         isSubmitting,
                         isValid,
                         values,
                       }) => (
                <Form id="resetPasswordForm">
                  <div className="errorMsg" id="resetPassErrorMsg">
                    เกิดข้อผิดพลาด โปรดลองใหม่ในภายหลัง
                  </div>
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="pass" color={color.white} size={20} />
                    {errors.password && touched.password && (
                      <div className="error-new">{errors.password}</div>
                    )}
                    <Field
                      type="password"
                      name="password"
                      placeholder="รหัสผ่านใหม่"
                      id="password"
                      value={values.password || ''}
                      onClick={this.hideErrorMsg}
                    />
                  </div>
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="pass" color={color.white} size={20} />
                    {errors.passwordConfirmed && touched.passwordConfirmed && (
                      <div className="error-new">{errors.passwordConfirmed}</div>
                    )}
                    <Field
                      type="password"
                      name="passwordConfirmed"
                      placeholder="ยืนยันรหัสผ่านใหม่"
                      id="confirm-password"
                      value={values.passwordConfirmed || ''}
                    />
                  </div>
                  <BtnWrapper>
                    {!isSubmitting ?
                      <button aria-label="ส่ง" className="button gradient-button submit" type="submit"
                              disabled={!isValid}>
                        <span>ส่ง</span>
                      </button>
                      : <button aria-label="กำลังดำเนินการ" className="button gradient-button submit-loading" type="submit"
                                disabled={(!isValid || isSubmitting)}>
                        <span>กำลังดำเนินการ</span>
                        <IcomoonIcon icon="loader" color={color.transparentWhite7} size={16}/>
                      </button>
                    }
                    {status && status.msg && <div>{status.msg}</div>}
                  </BtnWrapper>
                </Form>
              )}
            />
          </div> :
          <div className="a-center">
            กรุณาเข้าสู่ระบบหรือขอลิงค์รีเซ็ตรหัสผ่านเพื่ออัปเดตรหัสผ่าน
          </div>
        }

      </Wrapper>
    );
  }
}

export default withLocation(ResetPasswordForm);

ResetPasswordForm.propTypes = {
  popupId: PropTypes.string,
};
